import React, { useState } from 'react';
import className from 'classnames/bind';
import style from './SearchInput.module.scss';

import SearchIcon from '@material-ui/icons/Search';

const cx = className.bind(style);

interface Props {
    className?: string,
    value?: string,
    placeholder?: string,
    type?: string,
    onSearch?: (keyword: string) => void,
    onChange?: (keyword: string) => void,
    icon?: boolean,
    width?: number
}

const SearchInput = ({
    className,
    value='',
    placeholder='',
    type='text',
    onSearch: handleSearch = ()=>{},
    onChange: handleChange = ()=>{},
    icon=true,
    width
}: Props) => {
    const [ focus, setFocus ] = useState<boolean>(false);
    const [ keyword, setKeyword ] = useState<string>(value);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter') handleSearch?.(keyword);
    };

    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.currentTarget.value);
        handleChange(e.currentTarget.value);
    };

    return (
        <div className={cx('search-input', className, { focus })} style={{ minWidth: width, width }}>
            <input
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={handleLocalChange}
                onKeyPress={handleKeyPress}
                value={keyword}
                type={type}
                placeholder={placeholder}
            />
            {icon &&
                <button onClick={() => handleSearch?.(keyword)}>
                    <SearchIcon/>
                </button>
            }
        </div>
    );
};

export default SearchInput;