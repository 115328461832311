export interface Menu {
    name: string,
    to: string,
    exact?: boolean
}

export interface MenuList {
    title: string,
    items: Menu[]
}


export interface TopSales {
    purchaseCount: number,
    totalPrice: number,
    price: number,
    count: number,
}

export interface TopResult {
    top40: TopSales,
    top80: TopSales,
    [key: string]: TopSales
}

export interface ProductResult {
    collection: string,
    purchaseConditionInfos: any[],
    rank: number,
    docid: string,
    id: string,
    parentId: string,
    category1Id: string,
    category2Id: string,
    category3Id: string,
    category4Id: string,
    categoryPlusId: string,
    category1Name: string,
    category2Name: string,
    category3Name: string,
    category4Name: string,
    category4NameOrg: string,
    categoryLevel: string,
    openDate: string,
    productName: string,
    productTitle: string,
    productTitleOrg: string,
    description: string,
    descriptionOrder: string,
    searchKeyword: string,
    lowPrice: number,
    highPrice: number,
    mobileLowPrice: number,
    screenFlag: string,
    price: string,
    mobilePrice: string,
    priceUnit: string,
    lowestCardPrice: any,
    lowestCardName: string,
    reviewCount: number,
    checkOutReviewCount: number,
    reviewCountSum: number,
    day14ReviewCount: string,
    monthReviewCount: string,
    yearReviewCount: string,
    imageUrl: string,
    additionalImageCount: number,
    mallCount: number,
    mallNo: string,
    mallId: string,
    mallName: string,
    mallNameOrg: string,
    mallProductId: string,
    mallProductUrl: string,
    mallProdMblUrl: string,
    mallPcUrl: string,
    purchaseCnt: number,
    mallInfoCache: any,
    crUrl: string,
    crUrlMore: string
}

export interface ShoppingResult {
    status: { code: string, message: string },
    query: string,
    stopwordQuery: string,
    strQueryType: string,
    nluTerms: { keyword: string, type: string }[],
    brandNo: string,
    makerNo: string,
    mallNo: string,
    isModelNameSearch: number,
    queryProperty: string,
    start: number,
    productCount: number,
    total: number,
    termCount: number,
    terms: string[],
    intersectionTermCount: number,
    intersectionTerms: any[],
    exclusionTermCount: number,
    exclusionTerms: any[],
    searchTime: number,
    switch_version_bt_on: string,
    qlty_imp_version: string,
    partialSearch: number,
    qrSearch: number,
    preferTag: string,
    priceRecognize: { prQuery: string, minValue: string, maxValue: string, susaStringCount: string },
    cmd: any,
    cmpOrg: any,
    products: ProductResult[],
    summaryMall: any,
    partialSearched: any
}

export interface ProductSales {
    shoppingResult: ShoppingResult,
    [key: string]: any
}

export interface ProductList {
    lastBuildDate?: string,
    total: number,
    start?: number,
    display?: number,
    items: Product[]
}

export interface Product {
    title: string,
    link: string,
    image: string,
    lprice: string,
    hprice: string,
    mallName: string,
    productId: string,
    productType: string,
    brand: string,
    maker: string,
    category1: string,
    category2: string,
    category3: string,
    category4: string
}

export interface RelKwdStat {
    keywordList: KeywordsTool[]
}

export interface KeywordsTool {
    relKeyword: string,
    monthlyPcQcCnt: string | number,
    monthlyMobileQcCnt: string | number,
    monthlyAvePcClkCnt: string | number,
    monthlyAveMobileClkCnt: string | number,
    monthlyAvePcCtr: number,
    monthlyAveMobileCtr: number,
    plAvgDepth: number,
    compIdx: string
}

export interface Estimate {
    device: 'PC' | 'MOBILE',
    period: 'DAY' | 'MONTH',
    estimate: Bid[]
}

export interface Bid {
    keyword: string,
    nccKeywordId: string,
    bid: number
}

export interface AveragePosition {
    device: 'PC' | 'MOBILE',
    estimate: EstimateBidByPosition[]
}

export interface EstimateBidByPosition {
    keyword: string,
    position: number,
    nccKeywordId: string,
    bid: number
}

export const productType: {[key: number]: any} = {
    1: {
        type: '가격비교 상품',
        group: '일반상품'
    },
    2: {
        type: '가격비교 비매칭 상품',
        group: '일반상품'
    },
    3: {
        type: '가격비교 매칭 상품',
        group: '일반상품'
    },
    4: {
        type: '가격비교 상품',
        group: '중고상품'
    },
    5: {
        type: '가격비교 비매칭 상품',
        group: '중고상품'
    },
    6: {
        type: '가격비교 매칭 상품',
        group: '중고상품'
    },
    7: {
        type: '가격비교 상품',
        group: '단종상품'
    },
    8: {
        type: '가격비교 비매칭 상품',
        group: '단종상품'
    },
    9: {
        type: '가격비교 매칭 상품',
        group: '단종상품'
    },
    10: {
        type: '가격비교 상품',
        group: '판매예정상품'
    },
    11: {
        type: '가격비교 비매칭 상품',
        group: '판매예정상품'
    },
    12: {
        type: '가격비교 매칭 상품',
        group: '판매예정상품'
    },
};

export const excludeNaverList = [ 'CJmall', 'G마켓' ];