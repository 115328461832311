import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import style from './Search.module.scss';
import SearchInput from '../components/controls/SearchInput';
import StickyTable, { StickyTableConfig } from '../components/controls/StickyTable';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { RouteChildrenProps, useHistory } from 'react-router';
import { debounce } from 'lodash';
import { useAppSelector } from '../app/hooks';
import { selectAccessToken } from '../app/LoginSlice';
import Fetch from '../lib/Fetch';

const cx = className.bind(style);

const Search = ({ location }: RouteChildrenProps) => {
    const accessToken = useAppSelector(selectAccessToken)
    const fetch = new Fetch(accessToken);

    const history = useHistory();
    const { keyword, price } = location.search.query2json() || { keyword: '', price: '0' };

    const handleChangeKeyword = debounce((keyword: string) => history.push(`/search?keyword=${keyword}&price=${price}`), 500);
    const handleSearch = (price: string) => history.push(`/search?keyword=${keyword}&price=${price}`);

    const [listData, ListData] = useState<any[]>([]);

    useEffect(() => {
        if(!keyword) return;

        fetch.get(`/search/shop?query=${keyword}&price=${price}`)
            .then(response => ListData(response.result.items.filter((v: any) => v.lprice < +price)))
            .catch(alert);
    }, [keyword, price]);

    
    const stickyConfig: StickyTableConfig = {
        items: [
            {
                header: <span></span>,
                width: 250,
                align: 'center'
            },
            {
                header: <span>상품명</span>,
                width: 300,
                resize: true
            },
            {
                header: <span>최저 가격</span>,
                width: 100,
                align: 'center'
            },
            {
                header: <span>최고 가격</span>,
                width: 100,
                align: 'center'
            },
            {
                header: <span>카테고리</span>,
                width: 300,
                align: 'center'
            },
            {
                header: <span>상점명</span>,
                width: 150,
                align: 'center'
            }
        ],
        sticky: 0
    };
    const [stickyBody, StickyBody] = useState<JSX.Element[][]>([]);

    useEffect(() => {
        const generatorCategory = (name: string) => name ? ' > ' + name : '';

        StickyBody(listData.map(data => [
            <div className={cx('image-wrapper')}><img src={data.image} alt={data.title} /></div>,
            <span dangerouslySetInnerHTML={{ __html: data.title }} />,
            <span>{data.lprice?.comma()} 원</span>,
            <span>{data.hprice?.comma() || data.lprice?.comma() || '0'} 원</span>,
            <span>{data.category1}{generatorCategory(data.category2)}{generatorCategory(data.category3)}{generatorCategory(data.category4)}</span>,
            <span>{data.mallName}</span>
        ]))
    }, [listData]);


    const handleClickRow = (rowIdx: number) => {
        window.open(listData[rowIdx]?.link);
    };
    
    return (
        <div id={cx('search-container')}>
            <h2>최저가 검사 도구</h2>
            <SearchInput className={cx('keyword-input')} value={keyword} icon={false} placeholder="검색어를 입력해주세요." onChange={handleChangeKeyword} />
            <SearchInput className={cx('search-input')} value={price} type="number" width={250} placeholder="최저가를 입력해주세요." onSearch={handleSearch} />
            <div className={cx('help')} data-tooltip={`최저가 검수를 희망하는 상품의 상품명과 해당 제품의\n최저가를 입력하시면 기준 미준수 제품이 표시됩니다.`} ><LiveHelpIcon/></div>
            {keyword &&
                <>
                    <h2>검사 결과</h2>
                    <StickyTable config={stickyConfig} body={stickyBody} onClickRow={handleClickRow} height={250} />
                </>
            }
        </div>
    );
};

export default Search;