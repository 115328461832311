import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import style from './ImageExtractor.module.scss';
import SearchInput from '../components/controls/SearchInput';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { RouteChildrenProps, useHistory } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { selectAccessToken } from '../app/LoginSlice';
import Fetch from '../lib/Fetch';

const SearchInputMemo = React.memo(SearchInput);

const cx = className.bind(style);

const ImageExtractor = ({ location }: RouteChildrenProps) => {
    const accessToken = useAppSelector(selectAccessToken)
    const fetch = new Fetch(accessToken);

    const history = useHistory();
    const uri = decodeURI(location.search.query2json()?.uri || '');
    // uri
    // const [uri, Uri] = useState<string>('https://smartstore.naver.com/furincess/products/5141802921');

    const handleChangeUri = (keyword: string) => history.push(`/extractor?uri=${encodeURI((keyword.replace(/\?[\s\S]*/g, '')))}`);


    // image
    const [imageList, ImageList] = useState<any[]>([]);
    const [selectImageList, SelectImageList] = useState<any[]>([]);

    useEffect(() => {
        if(!uri) return;
        ImageList([]);
        SelectImageList([]);

        const smartStoreReg = /^(http|https):\/\/smartstore.naver.com\/[a-zA-Z0-9\-_]+\/products\/\d+($|\?)/i;
        if(!smartStoreReg.test(uri)) {
            alert('스마트스토어만 검색이 가능하며, 상품 페이지의 url을 입력해주세요.');
            return;
        }

        fetch.post(`/image/extract`, { uri })
            .then(response => ImageList(response.result))
            .catch(alert);
    }, [uri]);

    const handleChangeSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = imageList[+e.currentTarget.value].data;
        const extension = imageList[+e.currentTarget.value].extension;

        if(e.currentTarget.checked) {
            if(!selectImageList.includes(value)) SelectImageList(imageList => [ ...imageList, { data: value, extension } ]);
        } else {
            const idx = selectImageList.findIndex(v => v.data === value);
            if(idx > -1) SelectImageList(imageList => imageList.filter(v => v.data !== value));
        }
    };


    // download
    const downloader = (list: any[]) => {
        list.forEach(({ data, extension }, idx) => {
            const el = document.createElement('a');
            const fileName = /http(|s):\/\/smartstore.naver.com\/([a-zA-Z0-9]+)\//.exec(uri)?.[2];

            el.href = data;
            el.download = `${fileName}_${idx+1}.${extension}`;
            document.body.appendChild(el);
            
            el.click();
            el.remove();
        });
    };

    const handleClickSelectImageDownload = () => downloader(selectImageList);
    const handleClickAllImageDownload = () => downloader(imageList);


    // fullscreen
    const handleClickFullscreen = (e: React.MouseEvent<SVGSVGElement>) => {
        const idx = +(e.currentTarget.getAttribute('data-idx') || -1);
        window.open(imageList[idx].uri);
    };

    return (
        <div id={cx('image-extractor-container')}>
            <h2>스마트 스토어 이미지 추출</h2>
            <SearchInputMemo value={uri} onSearch={handleChangeUri} placeholder="네이버 스마트 스토어 링크를 입력해주세요." />
            {!!imageList?.length &&
                <>
                    <h2>
                        추출 이미지
                        <button className={cx('selected-download-btn')} disabled={!selectImageList?.length} onClick={handleClickSelectImageDownload}>선택 다운로드</button>
                        <button className={cx('all-download-btn')} onClick={handleClickAllImageDownload}>전체 다운로드</button>
                    </h2>
                    <div className={cx('image-list')}>
                        {imageList.map((image, idx) =>
                            <div key={idx} className={cx('image-wrapper')}>
                                <label>
                                    <img src={image.uri} alt="네이버 스마트 스토어 상세" />
                                    <input type="checkbox" value={idx} onChange={handleChangeSelectImage} />
                                    <span/>
                                </label>
                                <FullscreenIcon data-idx={idx} onClick={handleClickFullscreen}/>
                            </div>
                        )}
                    </div>
                </>
            }
        </div>
    );
};

export default ImageExtractor;