import React from 'react';
import className from 'classnames/bind';
import style from './App.module.scss';
import LeftMenu from './pages/LeftMenu';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Main from './pages/Main';
import KeywordTool from './pages/KeywordTool';
import Advertising from './pages/Advertising';
import ImageExtractor from './pages/ImageExtractor';
import Search from './pages/Search';
import Category from './pages/Category';
import Rank from './pages/Rank';

const cx = className.bind(style);

const CustomRoute = ({ component: Component, ...routeProps }: any) => (
  <Route {...routeProps} render={props => (
    <>
      <LeftMenu/>
      <Component {...props}/>
    </>
  )}/>
);

const App = () => {
  return (
    <div id={cx('app-container')}>
      <BrowserRouter>
        <Switch>
          <CustomRoute path="/" exact component={Main}/>
          <CustomRoute path="/category" exact component={Category}/>
          <CustomRoute path="/rank" exact component={Rank}/>
          <CustomRoute path="/keyword" exact component={KeywordTool}/>
          <CustomRoute path="/advertising" exact component={Advertising}/>
          <CustomRoute path="/search" exact component={Search}/>
          <CustomRoute path="/extractor" exact component={ImageExtractor}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
