import React, { Fragment, useEffect } from 'react';
import className from 'classnames/bind';
import style from './LeftMenu.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { MenuList } from '../interfaces';
import $ from 'scriptjs';

import Logo from '../assets/images/left_logo.png';
import Banner from '../assets/images/left_bnnsc_ban_01.jpg';
import KakaoLogin from '../assets/images/kakao_login.png';
import Fetch from '../lib/Fetch';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { login, logout, selectIsLogin } from '../app/LoginSlice';
// import EmailLogin from '../assets/images/email_login.png';

const cx = className.bind(style);

const LeftMenu = () => {
    const dispatch = useAppDispatch();
    const isLogin = useAppSelector(selectIsLogin);
    const fetch = new Fetch();
    const history = useHistory();
    const menu: MenuList[] = [
        {
            title: '상품',
            items: [
                { name: '아이템 발굴 (준비중)', to: "/category", exact: true },
                { name: '실시간 순위 (준비중)', to: "/rank", exact: true }
            ]
        },
        {
            title: '키워드',
            items: [
                { name: '키워드 도구', to: "/keyword", exact: true },
                { name: '광고전략 도구', to: "/advertising", exact: true }
            ]
        },
        {
            title: '꿀팁도구',
            items: [
                { name: '최저가검수 도구', to: "/search", exact: true },
                { name: '상세페이지 추출', to: "/extractor", exact: true }
            ]
        },
        // {
        //     title: '게시판',
        //     items: [
        //         { name: '공지사항', to: "/", exact: true },
        //         { name: '사장님 게시판', to: "/", exact: true }
        //     ]
        // },
    ];

    useEffect(() => {
        $('./kakao.js', () => {
            Kakao.init('163affa156f5236765f44091032abc96');
        });
    }, []);

    const handleClickKakaoLogin = () => {
        Kakao.Auth.login({
            success: cb => {
                fetch.get('/login/kakao', undefined, { Authorization: `${cb.token_type} ${cb.access_token}` })
                    .then(({ accessToken, result: user }: any) => dispatch(login({ accessToken, user })));
            },
            fail: cb => {
                alert('로그인에 실패하였습니다.');
                console.log(cb);
            }
        });
    };

    const handleClickLogout = () => {
        dispatch(logout());
    };

    // const handleClickEmailLogin = () => {

    // };

    return (
        <div id={cx('left-menu-container')}>
            <button className={cx('logo')} onClick={() => history.push('/')}><img src={Logo} alt="bananascout beta version" /></button>
            <div className={cx('menus')}>
                {!!menu?.length && menu.map(menu => (
                    <Fragment key={menu.title}>
                        <span className={cx('title')}>{menu.title}</span>
                        <ul className={cx('menu-list')}>
                            {!!menu.items?.length && menu.items.map(items => (
                                <li key={items.name} className={cx('menu-item')}>
                                    <NavLink to={items.to} exact={items.exact} activeClassName={cx('active')}>{items.name}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                ))}
            </div>
            <div className={cx('external-login')}>
                {
                    !isLogin ?
                    <button onClick={handleClickKakaoLogin} className={cx('kakao')}>
                        <img src={KakaoLogin} alt="카카오 로그인" />
                    </button> :
                    <button onClick={handleClickLogout} className={cx('kakao')}>
                        로그아웃
                    </button>
                }
                {/* <button onClick={handleClickEmailLogin} className={cx('email')}>
                    <img src={EmailLogin} alt="이메일 로그인/가입" />
                </button> */}
            </div>
            <div className={cx('banner-wrapper')}>
								<iframe src="https://ads-partners.coupang.com/widgets.html?id=718236&template=carousel&trackingCode=AF8369539&subId=&width=203&height=282&tsource=" width="203" height="282" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"></iframe>
								<p>* 쿠팡 파트너스 활동을 통해 일정액의 수수료를 제공받을 수 있습니다.</p>
                {/*<a target="_blank" rel="noreferrer" href="http://bananab2b.co.kr"><img src={Banner} alt="최대 도매몰 바나나B2B" /></a>*/}
            </div>
        </div>
    );
};

export default LeftMenu;
