import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface User {
    id: number;
    uid: string;
    profileImage: string;
    name: string;
    phone: string | null;
    email: string | null;
    gender: string | null;
    isAdmin: number;
}

interface LoginSlice {
    isLogin: boolean,
    user?: User,
    accessToken?: string;
}

interface LoginReducer {
    accessToken: string;
    user: User;
}

const initialState: LoginSlice = {
    isLogin: false,
    user: undefined,
    accessToken: undefined
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<LoginReducer>) => {
            state.isLogin = true;
            state.accessToken = action.payload.accessToken;
            state.user = action.payload.user;
        },
        logout: state => {
            state.isLogin = false;
            state.accessToken = undefined;
            state.user = undefined;
        }
    }
});

export const { login, logout } = loginSlice.actions;

export const selectIsLogin = (state: RootState) => state.login.isLogin;
export const selectUser = (state: RootState) => state.login.user;
export const selectAccessToken = (state: RootState) => state.login.accessToken;

export default loginSlice.reducer;