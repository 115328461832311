import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import style from './Advertising.module.scss';
import SearchInput from '../components/controls/SearchInput';
import StickyTable, { StickyTableConfig } from '../components/controls/StickyTable';
import { AveragePosition, KeywordsTool } from '../interfaces';
import { RouteChildrenProps, useHistory } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { selectAccessToken } from '../app/LoginSlice';
import Fetch from '../lib/Fetch';

const cx = className.bind(style);

const Advertising = ({ location }: RouteChildrenProps) => {
    const accessToken = useAppSelector(selectAccessToken)
    const fetch = new Fetch(accessToken);

    const history = useHistory();
    const { keyword } = location.search.query2json() || { keyword: '' };
    // const [keyword, Keyword] = useState<string>('원피스');
    const handleSearchKeyword = (searchKeyword: string) => history.push(`/advertising?keyword=${searchKeyword}`);

    // table
    const tableConfig: StickyTableConfig = {
        items: [
            {
                header: <span></span>,
                width: 100
            },
            {
                header: <span>상품명</span>,
                width: 300,
                resize: true
            },
            {
                header: <span>상점명</span>,
                width: 100,
                resize: true,
                align: 'center'
            },
            {
                header: <span>카테고리</span>,
                width: 300,
                resize: true
            },
            {
                header: <span>가격</span>,
                width: 100,
                resize: true,
                align: 'center'
            },
            {
                header: <span>등록일</span>,
                width: 100,
                align: 'center'
            },
            {
                header: <span>리뷰</span>,
                width: 100,
                align: 'center'
            }
        ],
        sticky: 3
    };


    // data 
    const [tableBody, TableBody] = useState<JSX.Element[][]>();
    const [countData, CountData] = useState<KeywordsTool>();
    const [avgData, AvgData] = useState<{[key: string]: AveragePosition}>();
    const countRatio = {
        pc: [0.056, 0.04, 0.043, 0.032, 0.071, 0.041, 0.056, 0.054, 0.053, 0.052, 0.066, 0.076],
        mobile: [0.056, 0.04, 0.043, 0.032, 0.071, 0.041, 0.056, 0.054, 0.053, 0.052, 0.066, 0.076].reverse()
    };

    useEffect(() => {
        const generatorCategory = (name: string) => name ? ' > ' + name : '';

        fetch.get(`/keywordstool?keyword=${keyword}`)
            .then(response => CountData(response.result.keywordList?.[0]))
            .catch(alert);
        
        fetch.get(`/estimate/average?keyword=${keyword}`)
            .then(response => AvgData(response.result))
            .catch(alert);
        
        fetch.get(`/shopping/list?keyword=${keyword}`)
            .then(response => TableBody((response.result as any[]).map(({ item: body }) => [
                <div className={cx('table-image')}><img src={body.imageUrl} alt={body.productTitle} /></div>,
                <span>{body.productTitle}</span>,
                <span>{body.mallName || '묶음 상품'}</span>,
                <span>{body.category1Name}{generatorCategory(body.category2Name)}{generatorCategory(body.category3Name)}{generatorCategory(body.category4Name)}</span>,
                <span>{body.lowPrice.comma()}원</span>,
                <span>{body.openDate.slice(0,4)}.{body.openDate.slice(4,6)}</span>,
                <span>{body.reviewCountSum.comma()}개</span>
            ])))
            .catch(alert);
    }, [keyword]);

    // PC 예상 검색 수
    const pcSearchCount = (): number => {
        if(!countData?.monthlyPcQcCnt || typeof countData.monthlyPcQcCnt === 'string') return 0;
        return Math.round(countData.monthlyPcQcCnt - (countData.monthlyPcQcCnt * countRatio.pc[keyword.length]));
    };

    // 모바일 예상 검색 수
    const mobileSearchCount = (): number => {
        if(!countData?.monthlyMobileQcCnt || typeof countData.monthlyMobileQcCnt === 'string') return 0;
        return Math.round(countData.monthlyMobileQcCnt - (countData.monthlyMobileQcCnt * countRatio.mobile[keyword.length]));
    };

    // PC 예상 클릭 수
    const pcClickCount = (): number => {
        if(
            !countData?.monthlyPcQcCnt || typeof countData.monthlyPcQcCnt === 'string' ||
            !countData?.monthlyAvePcCtr || typeof countData.monthlyAvePcCtr === 'string'
        ) return 0;
        return Math.round(pcSearchCount() * countData.monthlyAvePcCtr / 20);
    };

    // 모바일 예상 클릭 수
    const mobileClickCount = (): number => {
        if(
            !countData?.monthlyMobileQcCnt || typeof countData.monthlyMobileQcCnt === 'string' ||
            !countData?.monthlyAveMobileCtr || typeof countData.monthlyAveMobileCtr === 'string'
        ) return 0;
        return Math.round(mobileSearchCount() * countData.monthlyAveMobileCtr / 20);
    };

    // PC 예상 평균 클릭 비용
    const pcClickPrice = (): number => {
        if(!avgData?.pc.estimate?.length) return 0;
        return Math.round(avgData.pc.estimate.reduce((prev, curr) => prev + curr.bid, 0) / avgData.pc.estimate.length);
    };

    // 모바일 예상 평균 클릭 비용
    const mobileClickPrice = (): number => {
        if(!avgData?.mobile.estimate?.length) return 0;
        return Math.round(avgData.mobile.estimate.reduce((prev, curr) => prev + curr.bid, 0) / avgData.mobile.estimate.length);
    };

    return (
        <div id={cx('advertising-container')}>
            <h2>광고 전략 키워드</h2>
            <SearchInput value={keyword} onSearch={handleSearchKeyword} placeholder="광고를 위한 상품명 또는 키워드를 입력해주세요." />
            {!!keyword &&
                <>
                    <h2>요약 정보</h2>
                    <div className={cx('count-wrapper')}>
                        <div className={cx('count-card-wrapper')}>
                            <ul className={cx('card-wrapper')}>
                                <li className={cx('card')}>
                                    <div className={cx('row')}><span className={cx('title')}>PC 예상 검색 수</span><span className={cx('value')}>{pcSearchCount().comma()}<em>건</em></span></div>
                                    <div className={cx('row')}><span className={cx('title')}>모바일 예상 검색 수</span><span className={cx('value')}>{mobileSearchCount().comma()}<em>건</em></span></div>
                                </li>
                                <li className={cx('card')}>
                                    <div className={cx('row')}><span className={cx('title')}>PC 예상 클릭 수</span><span className={cx('value')}>{pcClickCount().comma()}<em>건</em></span></div>
                                    <div className={cx('row')}><span className={cx('title')}>모바일 예상 클릭 수</span><span className={cx('value')}>{mobileClickCount().comma()}<em>건</em></span></div>
                                </li>
                            </ul>
                            <ul className={cx('card-wrapper')}>
                                <li className={cx('card')}>
                                    <div className={cx('row')}><span className={cx('title')}>PC 예상 평균 클릭 비용</span><span className={cx('value')}>{pcClickPrice().comma()}<em>원</em></span></div>
                                    <div className={cx('row')}><span className={cx('title')}>모바일 예상 평균 클릭 비용</span><span className={cx('value')}>{mobileClickPrice().comma()}<em>원</em></span></div>
                                </li>
                                <li className={cx('card')}>
                                    <div className={cx('row')}><span className={cx('title')}>PC 예상 비용</span><span className={cx('value')}>{(pcClickCount() * pcClickPrice()).comma()}<em>원</em></span></div>
                                    <div className={cx('row')}><span className={cx('title')}>모바일 예상 비용</span><span className={cx('value')}>{(mobileClickCount() * mobileClickPrice()).comma()}<em>원</em></span></div>
                                </li>
                            </ul>
                        </div>
                        <div className={cx('rank-wrapper')}>
                            <div className={cx('card')}>
                                <h3>순위별 광고 단가</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>순위</th>
                                            <th>PC</th>
                                            <th>MOBILE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!avgData?.mobile?.estimate?.length && avgData.mobile.estimate.map((estimate, idx) =>
                                            <tr key={idx}>
                                                <td>{idx+1}</td>
                                                <td>{avgData.pc.estimate[idx]?.bid.comma()} 원</td>
                                                <td>{estimate.bid.comma()} 원</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <h2>광고 전략 수집 내용</h2>
                    <StickyTable config={tableConfig} body={tableBody} />
                </>
            }
        </div>
    );
};

export default Advertising;