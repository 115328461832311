export {};

declare global {
    interface String {
        query2json: <T={[key: string]: string}>() => T | undefined,
        uncomma: () => number
        comma: () => string
    }

    interface Number {
        check: () => number
        comma: () => string
    }

    namespace Kakao {
        const cleanup: () => void;
        const init: (appKey: string) => void;
        const isInitialized: () => boolean;

        namespace Auth {
            const authorize: (parameter: {
                redirectUri: string;
                state?: string;
                scope?: string;
                throughTalk?: boolean;
            }) => void;

            const cleanup: () => void;

            const createLoginButton: (settings: {
                container: string | HTMLElement;
                lang?: 'kr' | 'en';
                size?: 'small' | 'medium' | 'large';
                success?: {
                    (callback: {
                        access_token: string;
                        refresh_token: string;
                        token_type: string;
                        expires_in: number;
                        scope: string;
                    }): void
                };
                fail?: {
                    (callback: {
                        error: string;
                        error_description: string;
                    }): void
                };
                always?: {
                    (callback: {
                        access_token: string;
                        refresh_token: string;
                        token_type: string;
                        expires_in: number;
                        scope: string;
                    } | {
                        error: string;
                        error_description: string;
                    }): void
                };
                scope?: string;
                persistAccessToken?: boolean;
                throughTalk?: boolean;
            }) => void;

            const getAccessToken: () => string;

            const getAppKey: () => string;

            const getRefreshToken: () => void;

            const getStatusInfo: (callback?: {(callback: { status: string; user: object; }): void}) => void;

            const login: (settings: {
                success?: {
                    (callback: {
                        access_token: string;
                        refresh_token: string;
                        token_type: string;
                        expires_in: number;
                        scope: string;
                    }): void
                };
                fail?: {
                    (callback: {
                        error: string;
                        error_description: string;
                    }): void
                };
                always?: {
                    (callback: {
                        access_token: string;
                        refresh_token: string;
                        token_type: string;
                        expires_in: number;
                        scope: string;
                    } | {
                        error: string;
                        error_description: string;
                    }): void
                };
                scope?: string;
                persistAccessToken?: boolean;
                throughTalk?: boolean;
            }) => void;

            const loginForm: (settings: {
                success?: {
                    (callback: {
                        access_token: string;
                        refresh_token: string;
                        token_type: string;
                        expires_in: number;
                        scope: string;
                    }): void
                };
                fail?: {
                    (callback: {
                        error: string;
                        error_description: string;
                    }): void
                };
                always?: {
                    (callback: {
                        access_token: string;
                        refresh_token: string;
                        token_type: string;
                        expires_in: number;
                        scope: string;
                    } | {
                        error: string;
                        error_description: string;
                    }): void
                };
                scope?: string;
                persistAccessToken?: boolean;
            }) => void;

            const logout: (callback?: {(): void}) => void;

            const setAccessToken: (accessToken: string, persist?: boolean) => void;

            const setRefreshToken: () => void;
        }
    }
}

// eslint-disable-next-line
String.prototype.query2json = function<T>() {
    if(!this) return undefined;

    const query = this.slice(0, 1) === '?' ? this.slice(1) : this;
    const queryArr = query.split('&');

    return queryArr.reduce((prev, curr) => {
        const [key, value] = curr.split('=');
        return {
            ...prev,
            [key]: decodeURI(value)
        };
    }, {}) as T;
};

// eslint-disable-next-line
String.prototype.uncomma = function() {
    return (+this.replace(/,/g, '')) || 0;
}

// eslint-disable-next-line
String.prototype.comma = function() {
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// eslint-disable-next-line
Number.prototype.comma = function() {
    return this.toString().comma();
}

// eslint-disable-next-line
Number.prototype.check = function() {
    return +this || 0;
}