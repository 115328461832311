import React, { useEffect, useRef } from 'react';
import className from 'classnames/bind';
import style from './FadeIn.module.scss';

const cx = className.bind(style);

interface Props {
    className?: string,
    children?: React.ReactNode,
    direction?: 'up' | 'down' | 'left' | 'right',
    delay?: number, // ms
    duration?: number,   // ms
    position?: string
}

const FadeIn = ({
    className,
    children,
    direction='up',
    delay=0,
    duration=1000,
    position='50%'
}: Props) => {
    const fadeEl = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleVisible = ([entry]: IntersectionObserverEntry[]) => {
            if(!entry?.isIntersecting || !fadeEl.current) return;

            setTimeout(() => {
                (entry.target as HTMLDivElement).style.opacity = '1';
                (entry.target as HTMLDivElement).style.transform = 'translate3d(0, 0, 0)';
            }, delay);
        };

        if(fadeEl.current && 'IntersectionObserver' in window) {
            const observer = new IntersectionObserver(handleVisible, { threshold: 0.7 });
            observer.observe(fadeEl.current);

            return () => observer?.disconnect();
        }
    }, []);

    return (
        <div
            className={cx('fade-in-container', className, direction)}
            style={{
                transition: `opacity ${duration}ms, transform ${duration}ms`,
                transform: `translate3d(${direction === 'right' ? '-' : ''}${
                    direction === 'left' || direction === 'right' ?
                    position :
                    0
                }, ${direction === 'down' ? '-' : ''}${
                    direction === 'up' || direction === 'down' ?
                    position :
                    0
                }, 0)`
            }}
            ref={fadeEl}
        >
            {children}
        </div>
    );
};

export default React.memo(FadeIn);