import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import className from 'classnames/bind';
import style from './StickyTable.module.scss';

const cx = className.bind(style);

export interface StickyTableConfig {
    items: StickyTableItem[],
    sticky?: number
}

export interface StickyTableItem {
    header: JSX.Element,
    width: number,
    resize?: boolean,
    align?: ('left' | 'right' | 'center')
}

interface Props {
    className?: string,
    config: StickyTableConfig,
    body?: JSX.Element[][],
    height?: number,
    onClickRow?: (index: number) => void
};

const StickyTable = ({ className, config: configProps, body, height, onClickRow: handleClickRow }: Props) => {
    const [ configState, setConfigState ] = useState<StickyTableConfig>(configProps);
    const [ resizeIdx, setResizeIdx ] = useState<number>(-1);

    const maxWidth = useMemo(() => configState.items.reduce((prev, curr) => prev + curr.width, 0), [configState]);
    const { items, sticky } = configState;

    // resizer mouse down, mouse up event
    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            if((e.target as HTMLDivElement).hasAttribute('data-resize-index')) {
                const index = +((e.target as HTMLDivElement).getAttribute('data-resize-index') || -1);
                setResizeIdx(index);
            }
        };
    
        const handleMouseUp = () => {
            setResizeIdx(-1);
        };

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    // resizer mouse move event when mouse down
    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if(resizeIdx > -1) {
                const tmpConfig = Object.assign({}, configState);
                
                tmpConfig.items[resizeIdx].width = tmpConfig.items[resizeIdx].width + e.movementX;
                setConfigState(tmpConfig);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [resizeIdx, configState]);


    // scroll sync (header + body)
    const headEl: React.RefObject<HTMLDivElement> = useRef<any>(null);
    const bodyEl: React.RefObject<HTMLDivElement> = useRef<any>(null);

    const handleScroll = useCallback((e: Event) => {
        const x = (e.currentTarget as HTMLDivElement).scrollLeft;
        
        bodyEl.current?.scrollTo(x, 0);
        headEl.current?.scrollTo(x, 0);
    }, [bodyEl, headEl]);

    useEffect(() => {
        if(!headEl || !bodyEl) return;

        const tmpHeadEl = headEl.current;
        const tmpBodyEl = bodyEl.current;

        tmpHeadEl?.addEventListener('scroll', handleScroll);
        tmpBodyEl?.addEventListener('scroll', handleScroll);
        return () => {
            tmpHeadEl?.removeEventListener('scroll', handleScroll);
            tmpBodyEl?.removeEventListener('scroll', handleScroll);
        }
    }, [headEl, bodyEl, handleScroll]);

    return (
        <div className={cx(className)}>
            <div className={cx('sticky-table-header-container')} ref={headEl}>
                <table width={maxWidth}>
                    <thead>
                        <tr>
                            {!!items?.length && items.map((item, idx) =>
                                <th
                                    key={idx}
                                    style={{
                                        width: `${item.width}px`,
                                        left: idx < (sticky ?? 0)
                                            ? items.reduce((prev, curr, reduce_idx) => prev + (reduce_idx < idx ? curr.width : 0), 0)
                                            : undefined
                                    }}
                                    className={cx({
                                        sticky: idx < (sticky ?? 0)
                                    })}
                                >
                                    <div className={cx(item.align)}>
                                        {item.header}
                                    </div>
                                    {!!item.resize &&
                                        <div
                                            className={cx('resizer')}
                                            data-resize-index={idx}
                                        />
                                    }
                                </th>
                            )}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className={cx('sticky-table-container')} ref={bodyEl}>
                <table width={maxWidth}>
                    <tbody>
                        {!!body?.length && body.map((column, bodyIdx) =>
                            <tr key={bodyIdx} onClick={() => handleClickRow?.(bodyIdx)} className={cx({ pointer: handleClickRow })}>
                                {!!column?.length && column.map((el, idx) =>
                                    <td
                                        key={idx}
                                        style={{
                                            width: `${items[idx].width}px`,
                                            height: `${height}px`,
                                            left: items.reduce((prev, curr, reduce_idx) => prev + (reduce_idx < idx ? curr.width : 0), 0)
                                        }}
                                        className={cx(items[idx].align, {
                                            sticky: idx < (sticky ?? 0)
                                        })}
                                    >
                                        <div className={cx(items[idx].align)}>
                                            {el}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StickyTable;