import React, { useEffect, useReducer, useRef, useState } from 'react';
import className from 'classnames/bind';
import style from './Slider.module.scss';

const cx = className.bind(style);

interface Props {
    children?: React.ReactNode
}

const Slider = ({
    children
}: Props) => {
    const sliderEl = useRef<HTMLDivElement>(null);
    const [selectedSlider, SelectedSlider] = useState<number>(0);
    const [positionX, PositionX] = useState<number>(0);
    const [forceUpdate, ForceUpdate] = useReducer(v => ++v, 0);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            SelectedSlider(
                selectedSlider >= ((children as React.ReactNodeArray).length - 1) ?
                0 :
                selectedSlider + 1
            );
        }, 5000);
        return () => clearTimeout(timer);
    }, [selectedSlider]);

    useEffect(() => {
        if(!sliderEl.current) return;

        const width = sliderEl.current.getBoundingClientRect().width;
        PositionX(width * selectedSlider * -1);
    }, [selectedSlider, forceUpdate]);

    useEffect(() => {
        const handleResize = () => ForceUpdate();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={cx('slider-container')} ref={sliderEl}>
            <div className={cx('slider-wrapper')} style={{ transform: `translate3d(${positionX}px, 0, 0)` }}>
                {children}
            </div>
            <ol className={cx('order-wrapper')}>
                {children && (children as React.ReactNodeArray).map((_, idx) =>
                    <li key={idx} className={cx('order', { active: selectedSlider === idx })}>
                        <button onClick={() => SelectedSlider(idx)}/>
                    </li>
                )}
            </ol>
        </div>
    );
};

export default Slider;