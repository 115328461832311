import React, { useEffect } from 'react';
import className from 'classnames/bind';
import style from './Rank.module.scss';
import { useHistory } from 'react-router';

const cx = className.bind(style);

const Rank = () => {
    const history = useHistory();

    useEffect(() => {
        alert('준비중입니다.');
        history.goBack();
    }, []);

    return (
        <div id={cx('rank-container')}></div>
    );
};

export default Rank;