import React, { useState, useEffect, useMemo } from 'react';
import className from 'classnames/bind';
import style from './KeywordTool.module.scss';

import SearchInput from '../components/controls/SearchInput';
import { excludeNaverList, ProductList, ProductSales, productType, RelKwdStat } from '../interfaces';
import StickyTable, { StickyTableConfig } from '../components/controls/StickyTable';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { RouteChildrenProps, useHistory } from 'react-router';
import Fetch from '../lib/Fetch';
import { useAppSelector } from '../app/hooks';
import { selectAccessToken } from '../app/LoginSlice';

const cx = className.bind(style);

const KeywordTool = ({ location }: RouteChildrenProps) => {
    const accessToken = useAppSelector(selectAccessToken)
    const fetch = new Fetch(accessToken);

    const history = useHistory();
    const { keyword } = location.search.query2json() || { keyword: '' };
    // const [ keyword, setKeyword ] = useState<string>(keywordQuery);

    const [ product, setProduct ] = useState<ProductList>({ total: 0, items: [] });
    const [ productSales, setProductSales ] = useState<ProductSales>();
    const [ keywordsTool, setKeywordsTool ] = useState<RelKwdStat>();
    const [ moreCategory, setMoreCategory ] = useState<boolean>(false);
    const [ autoComplete, AutoComplete ] = useState<string[]>([]);
    const [ body, setBody ] = useState<JSX.Element[][]>([]);
    const productList = product?.items;
    const config: StickyTableConfig = {
        items: [
            {
                header: <span>순위</span>,
                width: 70,
                align: 'center'
            },
            {
                header: <span>이미지</span>,
                width: 100,
                align: 'center'
            },
            {
                header: <span>상품명</span>,
                width: 500,
                resize: true,
                align: 'left'
            },
            {
                header: <span>카테고리</span>,
                width: 150,
                resize: true,
                align: 'left'
            },
            {
                header: <span>판매처</span>,
                width: 150,
                resize: true,
                align: 'left'
            },
            {
                header: <span>가격</span>,
                width: 150,
                resize: true,
                align: 'right'
            },
            {
                header: <span>상품군</span>,
                width: 150,
                resize: true,
                align: 'center'
            },
            {
                header: <span>상품종류</span>,
                width: 150,
                resize: true,
                align: 'center'
            },
        ],
        sticky: 3
    };

    const handleMouseDown = (e: MouseEvent) => {
        setMoreCategory(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [setMoreCategory]);

    /* 상품 목록 불러오기 */
    // const shoppingResult = useMemo(() => {
    //     if(!productSales?.shoppingResult?.products?.length) return {
    //         top40: { count: 0, price: 0, purchaseCount: 0, totalPrice: 0 },
    //         top80: { count: 0, price: 0, purchaseCount: 0, totalPrice: 0 }
    //     } as TopResult;

    //     return productSales.shoppingResult.products?.reduce((prev, product, idx) => {
    //         const price = product.highPrice ? (product.highPrice + product.lowPrice) / 2 : product.lowPrice;

    //         if(idx < 40)
    //             return {
    //                 ...prev,
    //                 top40: {
    //                     ...prev.top40,
    //                     purchaseCount: (prev?.top40?.purchaseCount ?? 0) + product.purchaseCnt,
    //                     totalPrice: (prev?.top40?.totalPrice ?? 0) + (price * product.purchaseCnt),
    //                     price: (prev?.top40?.price ?? 0) + price,
    //                     count: (prev?.top40?.count ?? 0) + 1,
    //                 },
    //                 top80: {
    //                     ...prev.top80,
    //                     purchaseCount: (prev?.top80?.purchaseCount ?? 0) + product.purchaseCnt,
    //                     totalPrice: (prev?.top80?.totalPrice ?? 0) + (price * product.purchaseCnt),
    //                     price: (prev?.top80?.price ?? 0) + price,
    //                     count: (prev?.top80?.count ?? 0) + 1,
    //                 }
    //             };
    //         else
    //             return {
    //                 ...prev,
    //                 top80: {
    //                     ...prev.top80,
    //                     purchaseCount: (prev?.top80?.purchaseCount ?? 0) + product.purchaseCnt,
    //                     totalPrice: (prev?.top80?.totalPrice ?? 0) + (price * product.purchaseCnt),
    //                     price: (prev?.top80?.price ?? 0) + price,
    //                     count: (prev?.top80?.count ?? 0) + 1,
    //                 }
    //             };
    //     }, {} as TopResult);
    // }, [productSales]);

    const monthlySearchCount = useMemo(() => {
        if(!keywordsTool?.keywordList?.length) return '0';

        return ((+keywordsTool.keywordList[0].monthlyPcQcCnt || 0) + (+keywordsTool.keywordList[0].monthlyMobileQcCnt || 0)).comma();
    }, [keywordsTool]);

    const monthlySearchRatio = useMemo(() => {
        if(!keywordsTool?.keywordList?.length) return {
            element: undefined,
            pcRatio: 0,
            mobileRatio: 0,
            pcCount: 0,
            mobileCount: 0
        };

        const total = (+keywordsTool.keywordList[0].monthlyPcQcCnt || 0) + (+keywordsTool.keywordList[0].monthlyMobileQcCnt || 0);
        const pc = Math.ceil((+keywordsTool.keywordList[0].monthlyPcQcCnt || 0) / total * 100) || 0;
        const mobile = Math.round((+keywordsTool.keywordList[0].monthlyMobileQcCnt || 0) / total * 100) || 0;

        return {
            element: (
                <div className={cx('ratio-display')}>
                    <span data-count={`키워드 검색 수: ${(+keywordsTool.keywordList[0].monthlyPcQcCnt || 0).comma()}건`}>PC {pc}%</span>
                    <span data-count={`키워드 검색 수: ${(+keywordsTool.keywordList[0].monthlyMobileQcCnt || 0).comma()}건`}>모바일 {mobile}%</span>
                    <div className={cx('pc-side')} style={{ width: `${pc}%` }}/>
                </div>
            ),
            pcRatio: pc,
            mobileRatio: mobile,
            pcCount: (+keywordsTool.keywordList[0].monthlyPcQcCnt || 0),
            mobileCount: (+keywordsTool.keywordList[0].monthlyMobileQcCnt || 0)
        };
    }, [keywordsTool]);

    useEffect(() => {
        if(!keyword) return;

        // 키워드 정보 및 상품 목록
        fetch.get(`/search/shop/keyword?query=${keyword}`)
            .then(response => setProduct(response.result))
            .catch(alert);

        // 상품 매출, 판매량, 가격
        fetch.get(`/search/shop/list?query=${keyword}`)
            .then(response => setProductSales(response.result))
            .catch(alert);

        // 상품 검색량
        fetch.get(`/keywordstool/summary?keyword=${keyword}`)
            .then(response => setKeywordsTool(response.result))
            .catch(alert);
        
        // 자동 검색어
        fetch.get(`/naver/autocomplete?keyword=${keyword}`)
            .then(response => AutoComplete(response.result))
            .catch(alert);
    }, [keyword]);

    useEffect(() => {
        setBody(productList.map((item: any, idx: number) => [
            <span>{idx+1}</span>,
            <img className={cx('product-image')} src={item.image} alt={item.title} />,
            <a
                target="_blank"
                rel="noreferrer"
                href={item.link}
                className={cx('product-title')}
                dangerouslySetInnerHTML={{__html: item.title}}>
            </a>,
            <span>{item.category4 || item.category3 || item.category2 || item.category1}</span>,
            <span className={cx('flex')}>
                {
                    (item.lprice === '0' || item.hprice === '0') &&
                    !excludeNaverList.includes(item.mallName) &&
                    <i className={cx('naver')} />
                }
                {excludeNaverList.includes(item.mallName) && <i className={cx('icon'+excludeNaverList.indexOf(item.mallName))} /> }
                {
                    item.mallName === '네이버' ?
                    '묶음 상품' :
                    item.mallName
                }
            </span>,
            <span>{(item.lprice as Number).comma()} 원</span>,
            <span>{productType[item.productType].group}</span>,
            <span>{productType[item.productType].type}</span>,
        ]));
    }, [productList]);
    /* 상품 목록 불러오기 끝 */

    // 카테고리 종류별로 탐색 및 비율 계산
    const getCategory = useMemo(() => {
        if(!productList?.length) return;

        interface categoryList {
            count: number,
            category: string
        };

        const tmpProduct = productList.reduce((prev, curr) => {
            const category = [curr.category1, curr.category2, curr.category3, curr.category4].join('>');
            const categoryIndex = prev.findIndex(v => v.category === category);

            if(categoryIndex > -1) {
                ++prev[categoryIndex].count;
            } else {
                prev.push({ count: 1, category });
            }

            return prev;
        }, [] as categoryList[]);

        tmpProduct.sort((a, b) => b.count - a.count);

        return (
            <>
                <div className={cx({ active: moreCategory })}>
                    {!!tmpProduct?.length && tmpProduct.map(product => {
                        const category = product.category.split('>');

                        return (
                            <button key={product.category} className={cx('category')}>
                                {category[0] && <span>{category[0]}</span>}
                                {category[1] && <span><ArrowForwardIosIcon style={{fontSize:12,color:'#eee'}} />{category[1]}</span>}
                                {category[2] && <span><ArrowForwardIosIcon style={{fontSize:12,color:'#eee'}} />{category[2]}</span>}
                                {category[3] && <span><ArrowForwardIosIcon style={{fontSize:12,color:'#eee'}} />{category[3]}</span>}
                                <span className={cx('ratio')}>{`(${product.count / productList.length * 100}%)`}</span>
                            </button>
                        );
                    })}
                </div>
                {tmpProduct?.length > 1 && <button onClick={() => setMoreCategory(true)}>카테고리 더보기</button>}
            </>
        );
    }, [productList, moreCategory]);

    return (
        <div id={cx('keyword-tool-container')}>
            <h2>키워드 입력</h2>
            <div className={cx('search-wrapper')}>
                <SearchInput value={keyword} onSearch={keyword => history.push(`/keyword?keyword=${keyword}`)} placeholder="분석할 키워드를 입력해보세요. 예) 원피스" />
            </div>
            {keyword &&
                <>
                    <h2 className={cx('no-margin-top')}>검색 내역</h2>
                    <div className={cx('product-analysis')}>
                        <h3>상품 분석</h3>
                        <div className={cx('flex')}>
                            <div className={cx('main-image')}>
                                {productList?.[0]?.image && <img src={productList?.[0]?.image} alt={keyword} />}
                            </div>
                            <div className={cx('information', 'flex-column', 'flex1')}>
                                <span className={cx('name')}>{keyword}</span>
                                <ul className={cx('link')}>
                                    <li><a target="_blank" rel="noreferrer" href={`http://bananab2b.co.kr/product/search.asp?sword=${keyword}`}>바나나B2B</a></li>
                                    <li><a target="_blank" rel="noreferrer" href={`https://search.naver.com/search.naver?query=${keyword}`}>네이버</a></li>
                                    <li><a target="_blank" rel="noreferrer" href={`https://search.shopping.naver.com/search/all?query=${keyword}`}>네이버 쇼핑</a></li>
                                    <li><a target="_blank" rel="noreferrer" href={`https://www.coupang.com/np/search?component=&q=${keyword}`}>쿠팡</a></li>
                                    <li><a target="_blank" rel="noreferrer" href={`https://s.1688.com/selloffer/offer_search.htm?keywords=${keyword}`}>1688</a></li>
                                </ul>
                                <div className={cx('categories')}>{getCategory}</div>
                            </div>
                        </div>
                        <div className={cx('flex', 'trend')}>
                            <div className={cx('card', 'left')}>
                                <div>
                                    <span className={cx('title')}>상품수</span>
                                    <span className={cx('count')}>{product.total.comma()}<em>개</em></span>
                                </div>
                                <div>
                                    <span className={cx('title')}>한 달 검색수</span>
                                    <span className={cx('count')}>{monthlySearchCount}<em>회</em></span>
                                </div>
                                <div>
                                    <span className={cx('title')}>검색 비율</span>
                                    <span className={cx('count')}>{monthlySearchRatio.element}</span>
                                </div>
                            </div>
                            <div className={cx('card', 'right')}>
                                <div>
                                    <span className={cx('main-title')}>Top 40</span>
                                    <div>
                                        <span className={cx('count')}><em>매출액</em></span>
                                        <span className={cx('count')}>{Math.ceil((productSales?.top40.totalPrice / 10000) || 0).comma()}<em>만원</em></span>
                                    </div>
                                    <div>
                                        <span className={cx('count')}><em>판매량</em></span>
                                        <span className={cx('count')}>{Math.ceil(productSales?.top40.purchaseCount || 0).comma()}<em>개</em></span>
                                    </div>
                                    <div>
                                        <span className={cx('count')}><em>평균 가격</em></span>
                                        <span className={cx('count')}>{(Math.ceil((productSales?.top40.price / productSales?.top40.count / 100) * 100) || 0).comma()}<em>원</em></span>
                                    </div>
                                </div>
                                <div>
                                    <span className={cx('main-title')}>Top 80</span>
                                    <div>
                                        <span className={cx('count')}><em>매출액</em></span>
                                        <span className={cx('count')}>{Math.ceil((productSales?.top80.totalPrice / 10000) || 0).comma()}<em>만원</em></span>
                                    </div>
                                    <div>
                                        <span className={cx('count')}><em>판매량</em></span>
                                        <span className={cx('count')}>{Math.ceil(productSales?.top80.purchaseCount || 0).comma()}<em>개</em></span>
                                    </div>
                                    <div>
                                        <span className={cx('count')}><em>평균 가격</em></span>
                                        <span className={cx('count')}>{(Math.ceil((productSales?.top80.price / productSales?.top80.count / 100) * 100) || 0).comma()}<em>원</em></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={cx('flex', 'analytics')}>
                            <div className={cx('card')}>
                                <div className={cx('flex-column')}>
                                    <span>경쟁강도</span>
                                    <span>{(product.total / monthlySearchCount.uncomma()).check().toFixed(2)}</span>
                                </div>
                                <div className={cx('flex-column')}>
                                    <span>광고클릭률</span>
                                    <span>{keywordsTool?.keywordList?.[0].monthlyAvePcCtr + keywordsTool?.keywordList?.[0].monthlyAveMobileCtr}</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className={cx('search-keyword')}>
                        <h3>자동 완성 검색어</h3>
                        <ul>
                            {!!autoComplete?.length && autoComplete.map((keyword, idx) =>
                                <li key={idx} onClick={() => history.push(`/keyword?keyword=${keyword}`)}># {keyword}</li>
                            )}
                        </ul>
                    </div>
                    <div className={cx('product-list')}>
                        <h3>상품 목록</h3>
                        <StickyTable className={cx('table')} config={config} body={body}/>
                    </div>
                </>
            }
        </div>
    );
};

export default KeywordTool;