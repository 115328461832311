import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import style from './Main.module.scss';
import Slider from '../components/Slider';
import FadeIn from '../components/FadeIn';

import Slide01 from '../assets/images/slide01.jpg';
import Slide02 from '../assets/images/slide02.jpg';
import Main01 from '../assets/images/main01.jpg';
import Main02 from '../assets/images/main02.png';
import Main03 from '../assets/images/main03.png';
import Main04 from '../assets/images/main04.png';
import Logo from '../assets/images/left_logo.png';
import SearchInput from '../components/controls/SearchInput';
import { RouteChildrenProps } from 'react-router';
import Fetch from '../lib/Fetch';

const cx = className.bind(style);

const Main = ({ history }: RouteChildrenProps) => {
    const fetch = new Fetch();
    const [count, setCount] = useState<{loginCount: number; searchCount: number;}>({ loginCount: 0, searchCount: 0 });

    useEffect(() => {
        fetch.get('/history/count')
            .then(response => setCount(response.result));
    }, []);

    return (
        <div id={cx('main-container')}>
            <div className={cx('section-wrapper')}>
                <div className={cx('section', 'section-06')}>
                    <img src={Logo} alt="bananascout beta version" />
                    <SearchInput onSearch={keyword => history.push(`/keyword?keyword=${keyword}`)} placeholder="분석할 키워드를 입력해보세요. 예) 원피스" />
                </div>
            </div>
            <div className={cx('section-wrapper')}>
                <div className={cx('section', 'section-02')}>
                    <div className={cx('row')}>
                        <div className={cx('column')}>
                            <FadeIn direction="down"><span className={cx('title')}>오늘 <em>도움받은</em> 셀러님들</span></FadeIn>
                            <FadeIn direction="up" delay={500}><span className={cx('count')}><em>{((count.loginCount + count.searchCount) * 42).comma()}</em>명</span></FadeIn>
                        </div>
                        <div className={cx('column')}>
                            <FadeIn direction="down"><span className={cx('title')}>오늘 <em>검색된</em> 수량</span></FadeIn>
                            <FadeIn direction="up" delay={500}><span className={cx('count')}><em>{(count.searchCount * 55).comma()}</em>건</span></FadeIn>
                        </div>
                    </div>
                    <img src={Main01} alt="셀러" />
                </div>
            </div>
            <div className={cx('section-wrapper')}>
                <div className={cx('section', 'section-03')}>
                    <FadeIn direction="down">
                        <p className={cx('title')}>01</p>
                        <p className={cx('title')}>아이템 발굴</p>
                    </FadeIn>
                    <img src={Main02} alt="아이템 발굴" />
                    <FadeIn direction="up">
                        <p className={cx('content')}>카테고리와 기간/성별/연령 등의 디테일한 필터로 현재 트렌드로 뜨고 있는<br/>키워드 혹은 상품성과 잠재력이 높은 키워드를 확인할 수 있습니다.<br/>지금 바로 새로운 아이템을 발굴해 보세요!</p>
                    </FadeIn>
                </div>
            </div>
            <div className={cx('section-wrapper')}>
                <div className={cx('section', 'section-04')}>
                    <FadeIn direction="down">
                        <p className={cx('title')}>02</p>
                        <p className={cx('title')}>키워드 검색</p>
                    </FadeIn>
                    <img src={Main03} alt="키워드 검색" />
                    <FadeIn direction="up">
                        <p className={cx('content')}>카테고리와 기간/성별/연령 등의 디테일한 필터로 현재 트렌드로 뜨고 있는<br/>키워드 혹은 상품성과 잠재력이 높은 키워드를 확인할 수 있습니다.<br/>지금 바로 새로운 아이템을 발굴해 보세요!</p>
                    </FadeIn>
                </div>
            </div>
            <div className={cx('section-wrapper')}>
                <div className={cx('section', 'section-05')}>
                    <FadeIn direction="down">
                        <p className={cx('title')}>03</p>
                        <p className={cx('title')}>최저가 검수</p>
                    </FadeIn>
                    <img src={Main04} alt="최저가 검수" />
                    <FadeIn direction="up">
                        <p className={cx('content')}>카테고리와 기간/성별/연령 등의 디테일한 필터로 현재 트렌드로 뜨고 있는<br/>키워드 혹은 상품성과 잠재력이 높은 키워드를 확인할 수 있습니다.<br/>지금 바로 새로운 아이템을 발굴해 보세요!</p>
                    </FadeIn>
                </div>
            </div>
            <div className={cx('section-wrapper')}>
                <div className={cx('section', 'section-01')}>
                    <Slider>
                        <img src={Slide01} alt="온라인 사업자를 위한 필수 업무 툴" />
                        <img src={Slide02} alt="아이디어 제품 도매샵 바나나빌딩 B2B" />
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Main;