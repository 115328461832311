export default class Fetch {
    private readonly url: string = process.env.REACT_APP_API_HOST || '';
    originHeaders: any = {
        'Content-Type': 'application/json;charset=utf-8'
    };

    constructor(accessToken?: string) {
        if(accessToken) this.originHeaders = {
            ...this.originHeaders,
            authorization: `Bearer ${accessToken}`
        };
    }

    get = (path: string, param?: object, headers?: any) => {
        return fetch(`${this.url}${path}${this.json2query(param)}`, {
            method: 'get',
            headers: {
                ...this.originHeaders,
                ...headers,
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(response => response.json());
    }

    post = (path: string, body?: any, headers?: any) => {
        return fetch(`${this.url}${path}`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                ...this.originHeaders,
                ...headers,
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(response => response.json());
    }

    json2query = (json?: {[key: string]: any}) => {
        if(!json) return '';
    
        return '?' + Object.keys(json).reduce((prev, key, idx) => {
            const tmp = `${idx>0 ? '&' : ''}${key}=${json[key]}`;
            return prev + tmp;
        }, '');
    };
}